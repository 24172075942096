<template>
  <div class="textbox">
    <div class="bottombox">
      <div class="bottom_left">
        <div class="tabbox">
          <div v-for="(item, index) in arr" :key="index" v-if="item.hide==true" >
            <div class="tabtitle">{{item.laber}}</div>
            <div
            v-for="(items,number) in item.son"
            :key="number"
              class="tabbtn"
              :class="i == items.src ? 'active' : ''"
              @click="gettags(items)"
            >
              - {{items.laber}}
            </div>
          </div>
        </div>
      </div>
      <router-view> </router-view>
    </div>
  </div>
</template>

<script>
// import EditorBar from "../components/wangeditor";
// import timeFormat from "../util/time.js";

export default {
  components: {
    // EditorBar,
  },
  data() {
    return {
    
      arr: [
        {
          laber: "企业信息管理",
          hide:true,
          son: [
            {
              id: 1,
              laber: "企业服务信息",
              src:'/companyCenter/centerHome'
            },
            {
              id: 2,
              laber: "企业招聘信息",
              src:'/recruitCenter'
            },
            {
              id: 3,
              laber: "企业供需信息",
              src:'/supplyCenter'
            },
          ],
        },
        {
          laber: "企业管理",
          hide:true,
          son: [
            {
              id: 4,
              laber: "企业基本信息",
              src:'/basicCenter'
            },
            {
              id: 5,
              laber: "企业详细介绍",
              src:'/detailedCenter'
            },
          ],
        },
        {
          laber: "金融服务",
          hide:false,
          son: [
            {
              id: 6,
              laber: "金融服务信息",
              src:'/financeCenter'
            },
          ],
        },
        //    {
        //   laber: "自贸专栏",
        //   hide:false,
        //   son: [
        //     {
        //       id: 7,
        //       laber: "自贸专栏服务",
        //       src:'/ZMserverCenter'
        //     },
        //   ],
        // },
      ],
      UserObj:{},
      isCarre:''
    };
  },
  computed: {
    //onRoutes为菜单元素中的属性，:defalut-active="onRoutes" 对应的值

    i() {
  return this.$route.path.replace("/index", "");
    },
  },

  methods: {
    gettags(item){
     
      let src = item.src
      this.$router.push(src)
    },
    getradio(e) {
      console.log(e);
    },
    getindex() {
      this.$router.push("/");
    },
    // 获取企业信息
    getnewsList() {
      let that = this;
      let Account = window.localStorage.getItem("Account");
      that
        .$post("/api/UserInfo/ObjAccount", {
          Account: Account,
        })
        .then(function(data) {
            
          console.log(data);
           that.UserObj = data.Obj.UserObj
          that.isCarre = data.Obj.BuildingObj.SysID
          let id = data.Obj.UserObj.CompanySysID;
          if (id) {
            window.localStorage.setItem("CompanySysID", id);
           
          }
          //公行业为金额类企业才显示该菜单
          if(data.Obj.CompanyObj && data.Obj.CompanyObj.CompanyProfessionName.indexOf("金融") >= 0){
            that.arr[2].hide=true;
          }
            if(data.Obj.CompanyObj && data.Obj.CompanyObj.Type == 1){
            that.arr[3].hide=true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getperson() {
      this.$router.push("/personalCenter");
    },
    getnews() {
      this.$router.push("/newsCenter");
    },
    getcarrier() {
      this.$router.push("/carrierCenter");
    },
  },
updated() {
        window.scroll(0, 0);
    },
  mounted() {
    let that = this
    let Account = window.localStorage.getItem('Account')
    let CompanySysID=window.localStorage.getItem('CompanySysID')
    if (!Account) {
      that.$message.error('您还没有登录')
      setTimeout(() => {
        that.$router.push('/login')
      }, 2000);

      return
    }

       if (!CompanySysID) {
 
      setTimeout(() => {
        that.$router.push('/personalCenter')
      }, 2000);

      return
    }
    that.getnewsList();
  },
};
</script>
<style scoped lang="scss">






.textbox {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f6f6f6;
}
.topbox {
  width: 100%;
  background-color: #fff;
  height: 100px;
  margin-top: 30px;
}
.topboxinfor {
  width: 1200px;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  // background-color: aquamarine;
}
.topboxinfor img {
  float: left;
  height: 60px;
  margin-top: 20px;
  margin-right: 40px;
}

.bottombox {
  width: 1200px;
  min-height: 600px;
  margin-left: 50%;
  margin-top: 20px;
  transform: translateX(-50%);
  // background-color: aquamarine;
  overflow: hidden;
}
.bottom_left {
  float: left;
  // background-color: red;
  width: 300px;
  min-height: 600px;
}

.tabbox {
  width: 100%;
  border: 1px solid #eee;
  background-color: #fff;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.tabtitle {
  padding-left: 30px;
  box-sizing: border-box;
  height: 50px;
  line-height: 55px;
  font-size: 16px;
}
.tabbtn {
  height: 35px;
  line-height: 35px;
  padding-left: 40px;
  box-sizing: border-box;
  font-size: 14px;
  color: #555;
  cursor: pointer;
}
.active {
  border-left: 3px solid #409EFF;
  background-color: #f6f6f6;
}
.active1 {
  background-color: #e9e9e9;
}
.huanying {
  height: 60px;
  line-height: 30px;
  padding: 15px;
  width: 100%;
  background-color: #d9edf7;
  box-sizing: border-box;
  font-size: 14px;
  color: #666;
}
.smalltitle {
  position: relative;
  height: 36px;
  line-height: 6px;
  font-size: 14px;
  background-color: #f1f1f1;
  margin-top: 15px;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

@media (max-width: 500px) {
.textbox {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f6f6f6;
}

.bottombox {
  width: 100%;
  min-height: 600px;
  margin-left: 50%;
  margin-top: 20px;
  transform: translateX(-50%);
  // background-color: aquamarine;
  overflow: hidden;
}
.bottom_left {
  float: left;
  // background-color: red;
  width: 100%;
  min-height: 60px;
}

.tabbox {
  float: left;
  width: 100%;
  border: 1px solid #eee;
  background-color: #fff;
  padding-bottom: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.tabtitle {
  display: none;
  padding-left: 30px;
  box-sizing: border-box;
  height: 50px;
  line-height: 55px;
  font-size: 16px;
}
.tabbtn {
  text-align: center;
  width: 50%;
  float: left;
  height: 35px;
  line-height: 35px;
  padding-left: 0;
  box-sizing: border-box;
  font-size: 14px;
  color: #555;
  cursor: pointer;
}
.active {
  background-color: #409EFF;
  color: #fff;
}
.active1 {
  background-color: #e9e9e9;
}
.huanying {
  height: 60px;
  line-height: 30px;
  padding: 15px;
  width: 100%;
  background-color: #d9edf7;
  box-sizing: border-box;
  font-size: 14px;
  color: #666;
}
.smalltitle {
  position: relative;
  height: 36px;
  line-height: 6px;
  font-size: 14px;
  background-color: #f1f1f1;
  margin-top: 15px;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

}
</style>
